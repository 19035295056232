@import ../../../../styles/common/variables

.LearnNav
    position: relative
    z-index: 100

    .nav
        position: fixed
        left: 0
        top: 50%
        width: 100%
        padding-right: 25px

        pointer-events: none
        opacity: 0
        transform: translateY(-50%) scale(2)
        transition: none

    &.shown .nav
        pointer-events: all
        opacity: 1
        transform: translateY(-50%) scale(1)
        transition: all 0.35s ease-in-out

    .overlay
        position: fixed
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
        width: 100vw
        height: 100vh
        background: rgba(0, 0, 0, 0.85)

    .nav .container-fluid
        position: relative
        max-width: 1400px

        .close-btn
            position: absolute
            right: 0
            top: 0
            transform: translate(-50%, -100%)
            width: 32px
            height: 32px
            cursor: pointer

            &:before,
            &::after
                position: absolute
                left: 50%
                top: 50%
                width: 100%
                height: 2px
                content: ' '
                background: #fff
            &::before
                transform: translate(-50%, -50%) rotate(45deg)
            &::after
                transform: translate(-50%, -50%) rotate(-45deg)

    .nav .container-fluid > div
        text-align: center
        padding: 20px 0

        a
            display: inline-block
            border: 1px solid currentColor
            margin: 5px
            padding: 8px 15px
            color: #ffffff
            cursor: pointer
            font-size: 1.25em


.LearnNav > .toggle
    position: fixed
    left: 50%
    top: 15vh
    transform: translateX(calc(50vw - 81px))

    @media screen and (min-width: 1420px)
        transform: translateX(632px)

    @media screen and (min-width: 1480px)
        transform: translateX(672px)

    width: 40px
    height: 40px
    background: $highlight-color
    cursor: pointer

    &::before,
    &::after
        position: absolute
        left: 50%
        top: 50%
        width: 55%
        height: 2px
        content: ' '
        background: #ffffff

    &::before
        transform-origin: 0% 50%
        transform: translate(-50%, -150%) scale(0.75)
        transition: transform 0.25s ease-in-out
    &::after
        transform: translate(-50%, 150%)

    &:hover::before
        transform: translate(-50%, -150%) scale(1)
