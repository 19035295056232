@import ../../../../styles/common/variables
//*** Blog Styling ***//
.blog-details
    margin-right: 10px
    font-size: 13px
    line-height: 22px
    +respond-max-width(md)
        font-size: 12px
        line-height: 20px

    color: #7B7B7B
    display: inline-block
    margin-bottom: 5px
    overflow: hidden
    position: relative
    i
        vertical-align: top
        display: inline-block
        margin: 3px 5px 0 0
