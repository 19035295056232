@import ../../../../styles/common/variables

.learn-featured-container
    padding-top: 20px
    .first-row
        .featured-container
            height: 8em
            margin-bottom: 1em
            &:last-child
                margin-bottom: 0
        .featured-container-first
            height: 26em
    .second-row
        .featured-container
            margin-top: 0.5em
            height: 18em
    .featured-container
        position: relative
        &:hover
            .featured-description-container,
            .featured-content-external
                color: darken(#fff, 20%)

        a
            display: block
            height: 100%
            width: 100%
            position: relative
        .left-ribbon
            bottom: auto
            top: 10px
            +respond-max-width(xs)
                padding: 3px 20px
        .featured-image
            background-position: center
            background-size: cover
            height: 100%
            width: 100%

        .featured-description-container
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%)
            height: 5em
            padding: 2em 2em 1em 1em
            color: #fff
            font-size: 18px
            font-family: "Brutal_Bold", "OpenSans", "san-serif"
            +respond-max-width(xs)
                padding: 1em
                font-size: .85em
                height: 5em
                .featured-content-external
                    bottom: -10px
        .featured-content-external
            position: absolute
            bottom: 10px
            right: 0
            width: 30px
            height: 40px
            color: #fff
