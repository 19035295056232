@import ../../../../styles/common/variables

.community-content
    .content-box:nth-child(-n + 2)
        +respond-min-width(lg)
            width: 50%
    .tile-wrapper
        +respond-min-width(lg)
            width: 75%
            float: left

.community-blog-content
    .more-blog-items
        text-align: center
        background-color: #262626
        padding: 10px
        margin: 15px 15px 30px
        a
            color: #fff
            display: inline-block
            width: auto
            font-size: 20px
            font-weight: 400
            &.external-link
                &:after
                    bottom: 0
                    right: -25px
    .external-link
        +respond-max-width(md)
            width: auto

        &:after
            right: -2px
            bottom: 7px
            content: "\e601"
            font-family: 'icomoon'
            +respond-max-width(md)
                display: none !important
