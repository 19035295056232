@import ../../../../styles/common/variables

.epic-learn-page
    font-size: 16px

    a
        color: white

.samples-page
    .samples__hero
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: center
        -webkit-transition: all 1s ease
        transition: all 1s ease

        @media screen and (min-width: 850px)
            flex-direction: row

    .samples__link
        display: block
        flex: 1
        cursor: pointer
        color: white

        &:hover
            color: white

    .samples__bg
        -webkit-background-size: cover
        background-size: cover
        background-repeat: no-repeat
        background-position: center center
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        z-index: 1
        -webkit-transition: all 0.8s ease
        transition: all 0.8s ease

    .samples__overlay
        position: absolute
        z-index: 10
        left: 0
        right: 0
        bottom: 0
        top: 0
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)

    .samples__inner
        padding: 30px
        position: relative
        z-index: 100
        width: 75%

    .samples__featured
        margin: 30px 0
        height: 400px
        position: relative
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-end
        cursor: pointer

        &:first-child
            margin-right: 15px

        &:last-child
            margin-left: 15px

        &:hover
            .samples__overlay
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1) 100%)

            .samples__bg
                background-position: 50% 52%

    .samples__featured__title
        font-size: 28px
        margin-bottom: 15px
        font-family: "Brutal_Bold", "OpenSans", "san-serif"
        text-shadow: 2px 2px 3px rgba(0,0,0,.5)

    .samples__featured__description
        font-size: 16px
        font-family: "Brutal_Medium", "OpenSans", "san-serif"
        line-height: 24px
        text-shadow: 2px 2px 3px rgba(0,0,0,.5)

