//*** Activities Styling ***//
@import ../../../../styles/common/variables

.quote-box-wrapper
    margin-bottom: 20px
    color: #87888b
    display: block
    blockquote
        background: $alt-background !important
        border-left: 5px solid #ccc
        margin: 1.5em 10px
        color: #87888b
        padding: 15px
        position: relative
        &:before
            background: none !important
            border: 0 !important
            color: #9fa2a7 !important
            content: '\201c'
            font-size: 52px
            font-family: arial, 'sans-serif'
            font-style: italic
            line-height: 0.1em
            margin-right: 0.25em
            vertical-align: -0.4em
        &.hide-quote:before
            display: none
        &:hover
            text-decoration: none
        .quote-content
            display: inline
        .caret
            position: absolute
            bottom: -20px
            left: 20px
            border-right: 0
            border-top: 20px solid $alt-background
            border-left: 20px solid transparent
        p
            display: inline
            font-size: 13px
            line-height: 22px
            +respond-max-width(md)
                font-size: 12px
                line-height: 20px

            width: 99%
        .quote-author
            float: right
            font-size: 14px
            margin-top: 5px
            &:before
                content: '- '
    .quote-location
        margin: 10px 0 0 60px
        font-weight: 100
        color: #9fa2a7
        [class^="icon-"], [class*=" icon-"]
            margin: 1px 10px 0 0
            float: left
            font-size: 22px
            color: #C0C0C0
        strong
            font-weight: 500
            margin-bottom: 5px
            display: inline-block
            padding-right: 30px
            width: auto
            font-size: 18px
/** Activity image-layout styles.  Will apply instead of the quote blocks based on the activityImage property *

//use the calc and add vendor prefixes
=calc($key, $value)
    #{$key}: -webkit-calc(#{$value})
    #{$key}: -moz-calc(#{$value})
    #{$key}: calc(#{$value})

$white-smoke-color: #87888b
$activity-image-height: 180px
$activity-image-width: 120px
$activity-image-padding: 14px

/* holds the image and the text when the activity is not a block quote
.activity-image-layout
    margin: 25px 0
    height: $activity-image-height
    overflow: hidden
    color: white
    &:hover
        .activity-image-layout-content .content
            color: white
        .activity-image-container
            opacity: 1
    .container-fluid
        padding-left: 0
    .activity-image-container
        background-repeat: no-repeat
        background-position: center center
        -webkit-background-size: cover
        background-size: cover
        height: $activity-image-height
        width: $activity-image-width
        opacity: 0.8
        transition: opacity 0.25s ease-out

    .activity-image-layout-content
        +calc("width", "100% - #{$activity-image-width}")
        padding: $activity-image-padding
        height: $activity-image-height
        h3
            margin-top: 0
        .content
            color: #9a9a9a
            transition: color 0.2s ease-out
        .fader
            right: -$activity-image-padding
            width: initial
.activity-wrapper
    margin-top: -5px
