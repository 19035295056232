//*** Tile Styling ***//
@import ../../../../styles/common/variables

.community-content
    .content-box
        &.feature-content:nth-child(n+3)
            .caption
                height: 250px
        &.feature-content:nth-child(-n+2)
            .title
                font-size: 24px
        &.blog-content
            .caption
                height: 310px

.community-blog-content .blog-content, .community-content
    .caption
        height: 310px
    .blog-short
        color: #9a9a9a
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 7
        -webkit-box-orient: vertical


.thumbnail
    padding: 0
    +respond-max-width(md)
        .link-bar
            padding: 12px 20px 10px

    @media (max-width: 767px)
        height: auto !important
    .image-container
        position: relative
        background-position: center
    .link-bar
        width: 100%
        padding: 20px 20px 10px
        border-top: 1px solid #535353
        color: #f5f5f5
        margin-top: 10px
        position: relative
        transition: background-color 0.2s ease-out
        &:after
            position: absolute
            content: "\e601"
            font-family: icomoon
            right: 20px
            top: 8px
    h3
        text-align: center
        margin-top: 10px
        +respond-max-width(md)
            font-size: 20px
    p,
    .caption
        font-size: 13px
        line-height: 22px
        +respond-max-width(md)
            font-size: 12px
            line-height: 20px

        color: #8b908e
        > a
            color: #fff
    .caption
        padding: 12px 20px
        height: 240px
        +respond-max-width(xs)
            height: auto!important
        +respond-max-width(md)
            height: 220px
        overflow: hidden
        position: relative
    .left-ribbon
        left: 8px
        +respond-max-width(md)
            top: 107px
    &:hover
        .link-bar
            background-color: lighten($tile-color, 4%)
            border-top: 1px solid #3a3a3a

    .title
        font-size: 20px
        text-align: left
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 4
        -webkit-box-orient: vertical

        p
            color: whitesmoke
            font-size: 20px
