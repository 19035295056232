@import ../../../../styles/common/variables

//*** Spotlight Styling ***//

.spotlight-wrapper
    width: 100%
    +respond-min-width(lg)
        padding: 0
    h1
        margin-top: 6px

    .left-ribbon
        bottom: auto
        top: 10px
    .caption
        bottom: 20px
        +respond-max-width(md)
            bottom: 13px

        width: 100%
        background: rgba(0, 0, 0, 0.7)
        color: #fff
        -webkit-transition: all 0.2s ease
        transition: all 0.2s ease
        position: absolute
        transform: translate3d(0, 0, 0)
        left: 0
        padding: 5px 30px 5px 20px
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        line-height: 30px
        height: 40px
        opacity: 1
    .image-container
        margin-bottom: 14px

        +respond-min-width(md)
            height: 143px

        +respond-max-width(md)
            height: 122px

        +respond-min-width(lg)
            height: 212px
            margin-bottom: 20px

.spotlight-wrapper .external-link
    &:after
        right: 10px
        bottom: 27px
        +respond-max-width(md)
            bottom: 20px

        color: #fff