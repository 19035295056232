@import ../../../../styles/common/variables

.community-resources
    h3.title
        font-size: 20px

a:hover,
a:focus,
a:active
    text-decoration: none

// Reusable classes

.image-container
    background-repeat: no-repeat
    background-position: center center
    -webkit-background-size: cover
    background-size: cover
    +respond-min-width(md)
        height: 200px

    +respond-max-width(md)
        height: 170px

    width: 100%

.col-sm-4,
.col-md-4
    .image-container
        +respond-max-width(md)
            height: 180px
        +respond-max-width(xs)
            height: auto
    .thumbnail .caption
        +respond-max-width(md)
            height: 150px
        +respond-max-width(xs)
            height: auto

h1
    padding: 20px 0 10px
    border-bottom: 1px solid #3b3c3d
    margin-bottom: 20px
    &:empty
        border: 0 !important

.borderless-thumbnail .thumbnail
    background: none

//** External link positioning ***//

.external-link
    &:after
        color: #fff

.external-link.tile-box,
.alt-bg .external-link,
.blog-wrapper .external-link,
.quote-box.external-link
    &:after
        right: 10px
        bottom: 25px
        font-size: 18px
        color: #fff
        opacity: 1

.quote-box.external-link,
.header-content.external-link
    &:after
        right: -10px
        z-index: 99
        +respond-max-width(md)
            right: 10px

.external-link.tile-box
    &:after
        top: 228px
        +respond-max-width(md)
            top: 150px
        bottom: auto

strong.external-link
    &:after
        right: 8px
        bottom: 1px

.alt-bg .external-link
    &:after
        bottom: 5px

.tile .fader,
.media .fader,
.header-content > .fader,
.activity-image-layout-content > .fader
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(38, 38, 38, 1) 100%)
    height: 40px
    width: 100%
    position: absolute
    bottom: -9px
    left: 0
    z-index: 9
.header-content > .fader
    bottom: 0

.tile,
.media,
.header-content
    &:hover
        .fader
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(46, 46, 46, 1) 100%) !important

// Responsive tweak outside bootstrap defined aspects

@media (min-width: 768px) and (max-width: 773px)
    .potd-wrapper .col-sm-3,
    .spotlight-wrapper.col-sm-3
        width: 100%

@media (min-width: 1000px) and (max-width: 1200px)
    .community-header .image-container
        height: 334px

/** Reorder div's *

+respond-max-width(md)
    #tile-sidebar
        clear: both
        width: 100%
    #tile-sidebar > .potd-wrapper
        float: right
        width: 40%
    #tile-sidebar > .spotlight-wrapper
        float: left
        width: 60%

+respond-max-width(sm)
    #tile-sidebar
        clear: both
        width: 100%
    #tile-sidebar > .potd-wrapper
        float: right
        width: 100%
    #tile-sidebar > .spotlight-wrapper
        float: left
        width: 100%
