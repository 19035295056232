//*** Banner Styling ***//
@import ../../../../styles/common/variables

.community-header
    position: relative
    min-height: 282px
    background: $alt-background
    margin: 20px 0
    width: 97%
    margin-left: 15px

    .image-container
        &:hover
            opacity: 0.5
    .fader
        transition: background 0.2s ease-out
    .image-callout
        +respond-min-width(md)
            top: 183px

        +respond-max-width(md)
            top: 230px

        @media (min-width: 992px) and (max-width: 1200px)
            top: 234px
    .left-ribbon
        +respond-min-width(md)
            top: 290px

        +respond-max-width(md)
            top: 260px

        +respond-min-width(lg)
            top: 210px

        @media (min-width: 992px) and (max-width: 1200px)
            top: 250px
    .header-content
        padding-left: 30px
    h1
        padding: 15px 0 0
        border: 0
        +respond-max-width(md)
            padding: 10px 0 0
    .image-container
        transition: opacity 0.2s ease-out
        @media screen and (min-width: 700px)
            height: 300px
            float: left
            width: 50%
            margin-bottom: -10px
        +respond-max-width(md)
            height: 300px

        @media (min-width: 992px) and (max-width: 999px)
            height: 334px
    p
        color: #999
    .header-content-body
        color: #999
        margin-bottom: 40px
        p
            font-size: 13px
            line-height: 22px
            +respond-max-width(md)
                font-size: 12px
                line-height: 20px
    & > img
        position: absolute
        top: 0
        left: -10px
        +respond-max-width(md)
            width: 100%
    +respond-max-width(md)
        .row > div
            margin: 0 0 20px
            clear: both

    @media screen and (min-width: 700px)
        .header-content
            height: 300px
            float: left
            width: 47%
            position: relative
            .header-content-body
                height: 244px
                margin-bottom: 8px
                position: relative
                overflow: hidden
    @media screen and (min-width: 1200px)
        .header-content .header-content-body
            height: 180px