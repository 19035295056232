@import fonts
@import icons
@import "./common/_variables"
@import "./common/_scaffold"
@import "./launcher/_launcher"

.environment
    display: block
    position: fixed
    top: 0px
    width: 100%
    background: red
    z-index: 99999
    height: 2px
