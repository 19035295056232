//** UE Variables **//

//font
$page-font-family: "OpenSans", "san-serif"
$thin-font-weight: 100
$light-font-weight: 300
$regular-font-weight: 400
$bold-font-weight: 700
$extra-bold-font-weight: 900

$base-font-size: 11px
$large-font-size: 26px

// text
$highlight-text-color: white

// white
$base-text-color: whitesmoke

// off-white
$secondary-text-color: #9a9a9a

// light grey
$tertiary-text-color: #222222

// grey
$callToAction-text-color: #f15d0c

// orange
$pricing-text-color: #6dbd47

// green
$inverted-text-color: black

// black
$button-text-color: black

// black

$old-background: #1e1e1e
$web-background: #121212
$alt-background: lighten($web-background, 5%)

//elem colors
$highlight-color: #dca100
$disabled-highlight-color: #928b77
$active-tab: #6c6c6c
$active-item: #6dbd47
$indicator-color: #f15d0c
$tile-color: $alt-background
$twitchColor: #6441a5
$grantColor: #a58e3b
$eventsColor: #37ae8b
$staffPickColor: #ca5b34
$educationColor: #3586a5
$wikiColor: #b388dd
$docColor: #89c4f4
$answerHubColor: #fec956
$forumsColor: #56b273
$blogColor: #ff8750
$roadmapColor: #73c3b4
$trendingColor: #be3737
$addedColor: #59a543
$featureColor: #d59609
$updatedColor: #34b1c7

// page
$background: transparent

html, body
    background: $background
    color: $base-text-color
    font-family: $page-font-family
    font-weight: $regular-font-weight
    -webkit-touch-callout: none
    -webkit-user-select: none
    user-select: none

body *
    text-rendering: optimizeLegibility
    -webkit-font-feature-settings: "kern" 1
    font-feature-settings: "kern" 1

*
  -webkit-font-smoothing: subpixel-antialiased

body.ja
    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .learn-link,
    .learn-card-title,
    .featured-description-container,
    .title
        font-family: $font_JP !important

    .learn-card-title,
    .title,
    h3
        font-size: 18px !important
body.ko
    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .learn-link,
    .learn-card-title,
    .featured-description-container,
    .title
        font-family: $font_KR !important

    .learn-card-title,
    .title,
    h3
        font-size: 18px !important
body.zh_CN
    h1,
    h2,
    h3,
    h4,
    h5,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .learn-link,
    .learn-card-title,
    .featured-description-container,
    .title
        font-family: $font_SC !important
    .learn-card-title,
    .title,
    h3
        font-size: 18px !important
    .blog-short,
    .header-content-body,
    .learn-overlay-text,
    .content,
    .icon-bar li > a
        font-family: $font-zh_CN !important

a
    font-weight: $regular-font-weight

strong,
.intense
    font-weight: $bold-font-weight

h1,
h2,
h3,
h4,
h5
    text-align: left!important

h1,
h2,
h3
    font-weight: $light-font-weight
    font-family: "Brutal_Bold", "OpenSans", "san-serif"

h4,
h5
    font-weight: $regular-font-weight
    font-family: "Brutal_Medium", "OpenSans", "san-serif"

h1
    font-size: $base-font-size + 7

h2
    font-size: $base-font-size + 6

h3
    font-size: $base-font-size + 4

h4
    font-size: $base-font-size + 4

h5
    font-size: $base-font-size + 2

h1.large
    font-size: $base-font-size + 8
    font-family: "Brutal_Bold", "OpenSans", "san-serif"

h2.large
    font-size: $base-font-size + 7
    font-family: "Brutal_Bold", "OpenSans", "san-serif"

h3.large
    font-size: $base-font-size + 5
    font-family: "Brutal_Bold", "OpenSans", "san-serif"

h4.large
    font-size: $base-font-size + 5
    font-family: "Brutal_Bold", "OpenSans", "san-serif"

h5.large
    font-size: $base-font-size + 3
    font-family: "Brutal_Medium", "OpenSans", "san-serif"

h1.small
    font-size: $base-font-size + 6
    font-family: "Brutal_Medium", "OpenSans", "san-serif"

h2.small
    font-size: $base-font-size + 5
    font-family: "Brutal_Medium", "OpenSans", "san-serif"

h3.small
    font-size: $base-font-size + 2
    font-family: "Brutal_Medium", "OpenSans", "san-serif"

h4.small
    font-size: $base-font-size + 2
    font-family: "Brutal_Medium", "OpenSans", "san-serif"

h5.small
    font-size: $base-font-size + 1
    font-family: "Brutal", "OpenSans", "san-serif"

h1.extra-small
    font-size: $base-font-size + 5
    font-family: "Brutal", "OpenSans", "san-serif"

h2.extra-small
    font-size: $base-font-size + 4
    font-family: "Brutal", "OpenSans", "san-serif"

h3.extra-small
    font-size: $base-font-size + 1
    font-family: "Brutal", "OpenSans", "san-serif"

h4.extra-small
    font-size: $base-font-size + 1
    font-family: "Brutal", "OpenSans", "san-serif"

h5.extra-small
    font-size: $base-font-size
    font-family: "Brutal", "OpenSans", "san-serif"

.title
    font-size: $base-font-size + 14
    font-family: "Brutal_Bold", "OpenSans", "san-serif"

.title.large
    font-size: $base-font-size + 15
    font-family: "Brutal_Bold", "OpenSans", "san-serif"

.title.small
    font-size: $base-font-size + 13
    font-family: "Brutal_Medium", "OpenSans", "san-serif"

.title.extra-small
    font-size: $base-font-size + 12
    font-family: "Brutal", "OpenSans", "san-serif"

em,
.quote,
.subtitle,
.intense,
.empahasis
    font-style: italic

pre
    background-color: $alt-background
    color: $secondary-text-color

.title,
h4,
h5,
.small-title,
.extra-small,
.inverted,
.text-shadow
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.8)

.title
    color: $base-text-color

.inverted
    color: $inverted-text-color !important

.extra-small
    font-size: $base-font-size - 2

.small
    font-size: $base-font-size - 1

.large
    font-size: $base-font-size + 1

.tile
    border: 0
    border-radius: 0
    background-color: $tile-color
    transition: background-color 0.2s ease-out
    &:hover
        background-color: lighten($tile-color, 2%)
    .caption
        color: $base-text-color

.alt-bg
    background-color: $alt-background

.btn-primary
    background-color: $highlight-color
    border-color: $highlight-color
    color: $button-text-color
    &:hover
        background-color: darken($highlight-color, 5%)
        border-color: darken($highlight-color, 5%)
    &:active
        background-color: darken($highlight-color, 5%)
        border-color: darken($highlight-color, 5%)

/** Generic classes to push color *

.twitch-color
    background-color: $twitchColor !important
    border-color: $twitchColor !important
    &:before,
    &:after
        background-color: darken($twitchColor, 10%) !important
        border-color: darken($twitchColor, 10%) !important

.grants-color
    background-color: $grantColor !important
    border-color: $grantColor !important
    &:before,
    &:after
        background-color: darken($grantColor, 10%) !important
        border-color: darken($grantColor, 10%) !important

.events-color
    background-color: $eventsColor !important
    border-color: $eventsColor !important
    &:before,
    &:after
        background-color: darken($eventsColor, 10%) !important
        border-color: darken($eventsColor, 10%) !important

.staffpick-color
    background-color: $staffPickColor !important
    border-color: $staffPickColor !important
    &:before,
    &:after
        background-color: darken($staffPickColor, 10%) !important
        border-color: darken($staffPickColor, 10%) !important

.education-color
    background-color: $educationColor !important
    border-color: $educationColor !important
    &:before,
    &:after
        background-color: darken($educationColor, 10%) !important
        border-color: darken($educationColor, 10%) !important

.wiki-color
    background-color: $wikiColor !important
    border-color: $wikiColor !important
    &:before,
    &:after
        background-color: darken($wikiColor, 10%) !important
        border-color: darken($wikiColor, 10%) !important
        color: $wikiColor !important

.answerhub-color
    background-color: $answerHubColor !important
    border-color: $answerHubColor !important
    &:before,
    &:after
        background-color: darken($answerHubColor, 10%) !important
        border-color: darken($answerHubColor, 10%) !important
        color: $answerHubColor !important

.forum-color
    background-color: $forumsColor !important
    border-color: $forumsColor !important
    &:before,
    &:after
        background-color: darken($forumsColor, 10%) !important
        border-color: darken($forumsColor, 10%) !important
        color: $forumsColor !important

.blog-color
    background-color: $blogColor !important
    border-color: $blogColor !important
    &:before,
    &:after
        background-color: darken($blogColor, 10%) !important
        border-color: darken($blogColor, 10%) !important
        color: $blogColor !important

.roadmap-color
    background-color: $roadmapColor !important
    border-color: $roadmapColor !important
    &:before,
    &:after
        background-color: darken($roadmapColor, 10%) !important
        border-color: darken($roadmapColor, 10%) !important
        color: $roadmapColor !important

.trending-color
    background-color: $trendingColor !important
    border-color: $trendingColor !important
    &:before,
    &:after
        background-color: darken($trendingColor, 10%) !important
        border-color: darken($trendingColor, 10%) !important

.added-color
    background-color: $addedColor !important
    border-color: $addedColor !important
    &:before,
    &:after
        background-color: darken($addedColor, 10%) !important
        border-color: darken($addedColor, 10%) !important

.feature-color
    background-color: $featureColor !important
    border-color: $featureColor !important
    &:before,
    &:after
        background-color: darken($featureColor, 10%) !important
        border-color: darken($featureColor, 10%) !important

.updated-color
    background-color: $updatedColor !important
    border-color: $updatedColor !important
    &:before,
    &:after
        background-color: darken($updatedColor, 10%) !important
        border-color: darken($updatedColor, 10%) !important
