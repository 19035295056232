@import ../../../../styles/common/variables

#categories-container
    margin-top: 0

    .category-container
        .sub-header
            margin: 0 1em
            h2
                margin: 0
                font-size: 1.8em
    hr
        margin: 0.5em 0 1em 1em
        width: calc(100% - 2em)
        border-color: rgb(59, 60, 61)

    .asset-list-group
        display: flex
        flex-direction: row
        justify-content: flex-start
        flex-wrap: wrap
        align-items: stretch
        margin: 0

        .asset-block
            flex: 0 0 50%
            margin: 0
            padding: 1em
            display: flex

            @media screen and (min-width: 600px)
                flex: 0 0 33.333%

            @media screen and (min-width: 1100px)
                flex: 0 0 25%

    .learn-asset-link
        text-decoration: none
        display: flex
        background-color: #1f1f1f
        flex: 1

        &:hover
            background-color: #262626

    .learn-asset
        flex-grow: 1
        position: relative
        padding-bottom: 40px

        &:hover, &:focus, &:active
            .learn-overlay
                background-color: #262626

        .learn-resource
            height: 200px

            .learn-card-image
                height: 100%
                width: 100%
                background-position: center
                background-size: cover


        .learn-card-title
            color: #f5f5f5
            text-align: left
            font-size: 1.2em
            margin: 20px 0 0px 15px
            font-weight: 400
            text-shadow: 2px 2px 1px rgba(0,0,0,.8)
            font-family: "Brutal_Bold", "OpenSans", "san-serif"

        .learn-overlay-text
            font-size: 13px
            line-height: 22px
            color: #8b908e
            text-align: left
            padding: 1em
            padding-top: 2em
            font-family: "OpenSans", "san-serif"

        .learn-contents-bottom-wrapper
            border-top: 1px solid #535353
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            padding: 5px 20px
            color: #f5f5f5
            height: 40px

            .learn-contents-text
                font-size: .75em
                vertical-align: middle
                float: left
                padding: 6px 0 0

            .learn-contents-icons
                float: right
                i
                    color: #f5f5f5
                    font-size: 1.1em
                    display: block
                    float: left
                    padding: 6px 5px 0

