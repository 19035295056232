@font-face
    font-family: 'icomoon'
    src: url(./fonts/icons/icomoon.eot) format("embedded-opentype"), url(./fonts/icons/icomoon.woff) format("woff"), url(./fonts/icons/icomoon.ttf) format("truetype"), url(./fonts/icons/icomoon.svg) format("svg")
    font-weight: normal
    font-style: normal


[class^="icon-"], [class*=" icon-"]
    font-family: 'icomoon'
    speak: none
    font-style: normal
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

.icon-updated:before
    content: "\e903"

.icon-added:before
    content: "\e904"

.icon-feature:before
    content: "\e905"

.icon-trending:before
    content: "\e906"

.icon-wiki:before
    content: "\e60b"

.icon-blog:before
    content: "\e60c"

.icon-education:before
    content: "\e605"

.icon-staffpick:before
    content: "\e606"

.icon-grants:before
    content: "\e607"

.icon-answerhub:before
    content: "\e608"

.icon-blog2:before
    content: "\e609"

.icon-forum:before
    content: "\e60a"

.icon-roadmap:before
    content: "\e60d"

.icon-calendar:before
    content: "\e60f"

.icon-events:before
    content: "\e60f"

.icon-download:before
    content: "\e900"

.icon-read:before
    content: "\e901"

.icon-keyboard-arrow-right:before
    content: "\e600"

.icon-clock:before
    content: "\e014"

.icon-search:before
    content: "\e036"

.icon-head:before
    content: "\e074"

.icon-file:before
    content: "\e124"

.icon-arrow-right:before
    content: "\e604"

.icon-youtube:before
    content: "\ea98"

.icon-google-plus:before
    content: "\ea88"

.icon-facebook:before
    content: "\ea8c"

.icon-facebook3:before
    content: "\ea8e"

.icon-link-external:before
    content: "\e601"

.icon-youtube:before
    content: "\ea98"

.icon-video:before
    content: "\ea98"

.icon-watch:before
    content: "\ea98"

.icon-google-plus:before
    content: "\ea88"

.icon-facebook:before
    content: "\ea8c"

.icon-facebook3:before
    content: "\ea8e"

.icon-twitter:before
    content: "\ea91"

.icon-twitter3:before
    content: "\ea93"

.icon-play:before
    content: "\e902"

.icon-twitch:before
    content: "\e602"


