@import ../../../../styles/common/variables

$primary: #338ded
$primary-light: #33befd

#ue5-ea
    background-color: $content-bg
    font-size: 16px
    font-family: "Brutal", "OpenSans", "san-serif"

    h1, h2, h3, h4
        font-family: "Brutal_Bold", "OpenSans", "san-serif"

    .ue5-ea__hero
        height: 80vh
        -webkit-background-size: cover
        background-size: cover
        background-repeat: no-repeat
        background-position: center center
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: center
        padding: 10em 5em

        @media screen and (max-width: 700px)
            height: auto
            padding: 4em 2em

        .ue5-ea__hero__logo
            width: 500px
            min-width: 500px
            height: 87px
            min-height: 87px
            -webkit-background-size: cover
            background-size: cover
            background-repeat: no-repeat
            background-position: center center

        .ue5-ea__hero__title
            text-decoration: none
            color: white
            font-size: 2em
            border-bottom: none

        .ue5-ea__hero__description
            max-width: 500px
            margin: 1em 0
            line-height: 2em

            @media screen and (max-width: 700px)
                max-width: none

        .ue5-ea__hero__buttons
            display: flex
            flex-direction: row

            .ue5-ea__btn
                margin-right: 1em

                &:last-child
                    margin-right: 0

            @media screen and (max-width: 700px)
                flex-direction: column
                width: 100%
                align-items: center

                .ue5-ea__btn
                    margin: 0
                    margin-bottom: 1em

                &:last-child
                    margin-bottom: 0


    .ue5-ea__btn
        color: white
        padding: 1em 2em
        text-decoration: none
        margin: 0
        text-transform: uppercase
        letter-spacing: 1px
        border-radius: 0
        min-width: 250px
        display: inline-block
        text-align: center

        &:focus
            border-color: white
            border-radius: 0

        &.primary
            background: $primary
            background: linear-gradient(to right, $primary 0%, $primary-light 100%)
            border: 2px solid $primary
            border-image-source: linear-gradient(to right, $primary 0%, $primary-light 100%)
            border-image-slice: 1

            &:hover
                background: $primary-light
                background: linear-gradient(to right, $primary-light 0%, $primary 100%)

        &.secondary
            background: transparent
            border: 2px solid $primary
            border-image-source: linear-gradient(to right, $primary 0%, $primary-light 100%)
            border-image-slice: 1

            &:hover
                background: $primary
                background: linear-gradient(to right, $primary 0%, $primary-light 100%)

            &:focus
                border-color: white


    .ue5-ea__textblocks
        display: flex
        flex-direction: row

        .ue5-ea__textblock
            flex: 1
            padding: 2em

            &.left
                text-align: left

            &.center
                text-align: center

            &.right
                text-align: right

        .ue5-ea__textblock__title
            text-align: inherit !important
            font-family: "Brutal", "OpenSans", "san-serif"
            font-size: 2em
            max-width: 50%
            margin: 2em auto

    .ue5-ea__imagecards
        display: flex
        flex-direction: row
        padding: 2em 2em

        @media screen and (max-width: 800px)
            flex-direction: column
            align-items: center

        .ue5-ea__imagecard
            margin: 2em
            flex: 1

            &.left
                text-align: left !important
            &.right
                text-align: right !important
            &.center
                text-align: center !important


        .ue5-ea__imagecard__image
            img
                width: 100%

        .ue5-ea__imagecard__title
            font-size: 1.5em
            margin: 2em 0

        .ue5-ea__imagecard__desc
            line-height: 2em
            margin: 2em 0

    .ue5-ea__promos
        display: flex
        flex-direction: row

        @media screen and (max-width: 800px)
            flex-direction: column
            align-items: center

        .ue5-ea__promo
            flex: 1
            padding: 4em
            position: relative

            &.left
                text-align: left

            &.right
                text-align: right

            &.center
                text-align: center

        .ue5-ea__promo__overlay
            position: absolute
            z-index: 10
            left: 0
            right: 0
            bottom: 0
            top: 0
            background-color: rgba(0, 0, 0, 0.5)

        .ue5-ea__promo__bg
            -webkit-background-size: cover
            background-size: cover
            background-repeat: no-repeat
            background-position: center center
            position: absolute
            left: 0
            top: 0
            right: 0
            bottom: 0
            z-index: 1

        .ue5-ea__promo__inner
            border: 1px solid white
            padding: 2em
            text-align: inherit
            position: relative
            z-index: 100

        .ue5-ea__promo__eyebrow
            text-transform: uppercase
            font-size: 0.8em
            font-weight: bold
            letter-spacing: 2px

        .ue5-ea__promo__title
            font-weight: normal
            font-size: 2em
            text-align: center !important

        .ue5-ea__promo__desc
            margin: 2em 0
