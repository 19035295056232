//** POTD Wrapper **//
@import ../../../../styles/common/variables

.potd-wrapper
    width: 100%
    margin-top: 2px
    .tile
        position: relative
        display: block
        overflow: hidden
        width: 100%
        +respond-max-width(md)
            margin-top: 18px

        +respond-max-width(md)
            .image-container
                height: 400px

        .caption
            bottom: 0
            width: 100%
            background: rgba(0, 0, 0, 0.7)
            color: #fff
            -webkit-transition: all 0.2s ease
            transition: all 0.2s ease
            position: absolute
            transform: translate3d(0, 0, 0)
            left: 0
            padding: 5px 30px 5px 20px
            white-space: nowrap
            text-overflow: ellipsis
            overflow: hidden
            line-height: 30px
            height: 40px
            opacity: 1
    .external-link
        &:after
            right: 10px
            bottom: 7px
            top: auto

