//*** Links Styling ***//
@import ../../../../styles/common/variables

#icon-bar
    padding: 0 20px
    width: 100%
    margin: 0 0 -10px -10px
    text-align: center
    overflow: hidden
    position: relative
    .external-link
        &:after
            top: 18px
            right: -19px
            +respond-max-width(md)
                top: 12px
    ul
        list-style: none
        position: relative
        display: block
        text-align: center
        li
            position: relative
            display: inline-block
            margin: 10px 20px 0
            +respond-max-width(md)
                margin: 10px 15px 0

            @media (min-width: 700px) and (max-width: 760px)
                margin: 1em 2.2em 0
            [class^="icon-"], [class*=" icon-"]
                background-color: transparent !important
                margin: -1px 10px 0 0
                float: left
                font-size: 20px
                &:before
                    background-color: transparent !important
            a
                color: #a4a4a5
                font-size: 14px
                font-weight: 400
                outline: none
                transition: color 0.1s ease-out
                span
                    display: block
                    margin-top: 8px
                    margin-left: 5px
                    float: left
                &:hover
                    text-decoration: none
                    color: lighten(#a4a4a5, 10%)
                    outline: none
