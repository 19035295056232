@import ../../../../styles/common/variables

.learn-header
    width: 100%
    margin-top: 1.5em
    padding: 0
    .learn-links
        font-size: 1.4em
        overflow: hidden
        +respond-max-width(md)
            font-size: 16px
        +respond-max-width(sm)
            font-size: 12px
        .learn-link
            border-right: 1px solid #535353
            position: relative
            float: left
            width: 33.33333%
            padding-left: 10px
            padding-right: 10px
            text-align: center
            font-family: "Brutal", "OpenSans", "san-serif"
            &:last-child
                border-right: none
            a
                color: #a4a4a5
                &:hover, &:active, &:focus
                    color: lighten(#a4a4a5, 20%)
                i
                    margin-right: .5em
                    display: inline-block
                    vertical-align: middle
                    padding-bottom: .2em
                    font-size: 32px
                    +respond-max-width(sm)
                        font-size: 20px
                .icon-blog
                    color: #62a6c2
                .icon-video
                    color: #d56a6a
                .icon-wiki
                    color: #60be68
